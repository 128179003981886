<template>
  <div class="home">
    <!-- 首页topbanner -->

    <div class="topBanner">
      <!-- <img class="logo" src="../assets/logodasai_blue.png" alt /> -->
      <div class="welcome">
        <div class="userName"></div>
        |
      </div>
    </div>

    <!-- 登录框-->
    <p class="tip"></p>
    <div class="cont">
      <div class="form sign-in">
        <h2 style="margin-bottom: 28px">
          {{ title.main }}
        </h2>
        <h2>{{ title.sub }}</h2>
        <label>
          <span>手机号码</span>
          <input id="phoneNumber" ref="userName" type="Text" />
        </label>
        <label>
          <span>密码</span>
          <input id="password" ref="password" type="password" />
        </label>

        <!-- <label>
          <span>测试码</span>
          <input id="pro" ref="pro" type="password" />
        </label> -->

        <button type="button" class="submit" @click="login">登 录</button>
        <button type="button" class="fb-btn" @click="openTips">
          联系我们
          <!-- <span>facebook</span> -->
        </button>
      </div>
      <div class="sub-cont">
        <div class="img">
          <div class="img__text m--up">
            <h2 style="color: white">
              <i class="el-icon-warning-outline"></i> 注意事项
            </h2>
            <div class="notice">
              <p>1、{{ examContent.time }}</p>
              <p>
                2、请务必使用最新的Chrome浏览器访问，版本不低于83，保证电脑处于良好的网络环境，推荐下载及上传速度均高于512KB/s；
              </p>
              <p>3、登录系统后请核对个人信息，了解考试须知；</p>
              <p>
                4、请在规定时间完成作品创作，结束前提交比赛作品。考试时间结束时系统将会自动交卷；
              </p>
              <p>
                5、在考试过程中若因电源网络等问题造成中断，请尽快再次登录系统，最终参赛作品以最后一次提交的程序为准；
              </p>
              <p>
                6、在作答编程题时可以跳出系统并在本地编辑器制作作品，但是在作答完成后请作品以正确的方式提交，在提交时请注意代码格式及代码完整性；
              </p>
              <p>7、请严格按照比赛流程及要求操作，违反规则造成的后果自负。</p>
            </div>
          </div>
          <el-dialog title="提示" :visible.sync="tips" width="339px" style="height: 370px" @closed="tipClose()">
            <span style="text-algin: left">{{ tipWords }}</span>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="tips = false">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import loginResigter from "../components/loginResigter.vue";
import judgeFormat from "../utils/verification.js";
import { Loading } from "element-ui";

export default {
  name: "contest",
  components: {},
  data() {
    return {
      tips: false,
      tipWords: "",
      title: {
        main: "银川市第二十一届青少年科技创新大赛",
        sub: "信息技术通用测评"
      },
      examContent: {
        time: "线上测试时间:12月14日10:00-20:00",
      },
      form: {
        username: "",
        password: "",
        repassword: "",
        name: "",
        category: "",
        school: "",
        invitation: "",
        code: "",
      },
    };
  },
  methods: {
    openTips() {
      this.tipWords = `赛事组委会联系方式：
      王老师 18295018913
      刘老师 18395213358
      陈老师 18095490105`;
      this.tips = true;
    },
    login() {
      // this.tipWords = "暂未开放如有疑问请联系赛事组委会 18095490105";
      // this.tips = true;
      // return;

      const userName = this.$refs.userName.value;
      const password = this.$refs.password.value;

      console.log(userName, password);

      // !judgeFormat.phoneNumber(userName)
      if (!userName) {
        this.tipWords = "请核对您的手机号！";
        this.tips = true;
        return;
      }

      // if (this.$refs.pro.value != "steamleader") {
      //   this.tipWords = "请核对您的测试码！";
      //   this.tips = true;
      //   return;
      // }
      // else if (!judgeFormat.passWord(form.password)) {
      //   this.tipWords = "请确定您的密码格式！";
      //   this.tips = true;
      // }
      else {
        let loadingInstance = Loading.service({ fullscreen: true });
        this.$http
          .post("/user", {
            username: userName,
            password: password,
            mode: "loginExam",
          })
          .then((response) => {
            loadingInstance.close();
            console.log(response);
            if (response.data.status == "登录成功！") {
              // let category = response.data.res.data.categoryValue;
              // if (
              //   category.indexOf("C++") == -1 &&
              //   category.indexOf("Python") == -1 &&
              //   category.indexOf("Scratch") == -1
              // ) {
              //   this.$alert(
              //     "仅C++、Python、Scratch组别参加线上考试，您报名得组别为" +
              //       category,
              //     "失败",
              //     {
              //       confirmButtonText: "确定",
              //       callback: (action) => {},
              //     }
              //   );
              //   return;
              // }

              const examID = response.data.res.examID;
              const category = response.data.res.restInfo.category;

              this.$localStorage.set("login_status_username", userName);
              this.$localStorage.set("login_status_token", password);
              this.$localStorage.set("login_status_category", category);
              this.$localStorage.set("examID", examID);
              this.$localStorage.set(
                "steam_userInfo",
                response.data.res.restInfo
              );
              this.$alert(response.data.status, "成功", {
                confirmButtonText: "确定",
                callback: (action) => {
                  // let url =
                  //   process.env.NODE_ENV === "development"
                  //     ? "http://localhost:8080/#/notice"
                  //     : "https://exam.yckcs.com/#/notice";
                  // loadingInstance.close();
                  // window.open(url, "_self"); //跳转页面为了把url干掉

                  this.$router.push(`/notice`);
                  this.$message({
                    type: "success",
                    message: `登录成功`,
                  });
                },
              });
            } else {
              this.$alert(response.data.status + "请联系管理员", "失败", {
                confirmButtonText: "确定",
                callback: (action) => {
                  this.$message({
                    type: "error",
                    message: `登录失败`,
                  });
                },
              });
            }
          })
          .catch((error) => {
            loadingInstance.close();
            console.log(error);
            this.tipWords = error.data.status;
            this.tips = true;
          });
      }
    },
    jump(name) {
      this.$store.commit("changeCategory", name);
      this.$router.push(`/${name}`);
    },
    tipClose() {
      if (this.tipWords == "注册成功！") this.loginState = true;
      if (this.tipWords == "登录成功！") this.$router.push("/");
    },
    GetRequest() {
      var url = decodeURI(window.location.search); //获取url中"?"符后的字串
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        url = url.substr(1);
      }
      if (url) {
        var strs = url.split("&");
        for (var i = 0; i < strs.length; i++) {
          var srtArry = strs[i].split("=");
          var y = srtArry.shift();
          theRequest[y] = unescape(srtArry.join("="));
        }
      }
      return theRequest;
    },
  },
  mounted() {
    let parms = this.GetRequest(window.location.href);
    let { username, password, category, eeeID, test } = parms;
    //如果有用户信息
    if (parms.ttt) {
      let loadingInstance = Loading.service({ fullscreen: true });
      // if (test == "steamleader")
      this.login(username, password, category, eeeID);
      //获取token
    }
    console.log(parms);
  },
};
</script>

<style scoped>
.very_container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.el-button {
  background-color: #409eff;
}

.home {
  background: rgba(61, 129, 255, 0.9) !important;
  /* height: 100vh; */
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input,
button {
  border: none;
  outline: none;
  background: none;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.tip {
  font-size: 20px;
  margin: 40px auto 50px;
  text-align: center;
}

.cont {
  overflow: hidden;
  position: relative;
  width: 1150px;
  height: 550px;
  margin: 150px auto 100px;
  background: #fff;
}

.form {
  position: relative;
  width: 600px;
  height: 100%;
  -webkit-transition: -webkit-transform 1.2s ease-in-out;
  transition: -webkit-transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out, -webkit-transform 1.2s ease-in-out;
  padding: 50px 30px 0;
}

.sub-cont {
  overflow: hidden;
  position: absolute;
  left: 640px;
  top: 0;
  width: 1000px;
  height: 100%;
  padding-left: 260px;
  background: #fff;
  -webkit-transition: -webkit-transform 1.2s ease-in-out;
  transition: -webkit-transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out, -webkit-transform 1.2s ease-in-out;
}

button {
  display: block;
  margin: 0 auto;
  width: 260px;
  height: 36px;
  border-radius: 30px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}

.img {
  overflow: hidden;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 520px;
  height: 100%;
  padding-top: 360px;
}

.img:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 900px;
  height: 100%;
  background-image: url("https://7374-steamleader-155c4d-1301115590.tcb.qcloud.la/contest/teacher_loginBanner.png?sign=03b422a1d4812b0a716922ddc0d2d3d3&t=1592987375");
  /* background-size: cover; */
  -webkit-transition: -webkit-transform 1.2s ease-in-out;
  transition: -webkit-transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out, -webkit-transform 1.2s ease-in-out;
}

.img:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.cont.s--signup .img:before {
  -webkit-transform: translate3d(640px, 0, 0);
  transform: translate3d(640px, 0, 0);
}

.img__text {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 50px;
  width: 90%;
  padding: 0 20px;
  text-align: center;
  color: #fff;
  -webkit-transition: -webkit-transform 1.2s ease-in-out;
  transition: -webkit-transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out;
  transition: transform 1.2s ease-in-out, -webkit-transform 1.2s ease-in-out;
}

.img__text h2 {
  margin-bottom: 10px;
  font-weight: normal;
}

.img__text p {
  font-size: 16px;
  line-height: 1.5;
  margin: 10px auto;
}

.img__text p:first-child {
  margin-top: 20px;
}

.cont.s--signup .img__text.m--up {
  -webkit-transform: translateX(520px);
  transform: translateX(520px);
}

.img__text.m--in {
  -webkit-transform: translateX(-520px);
  transform: translateX(-520px);
}

.cont.s--signup .img__text.m--in {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.img__btn {
  overflow: hidden;
  z-index: 2;
  position: relative;
  width: 100px;
  height: 36px;
  margin: 0 auto;
  background: transparent;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  cursor: pointer;
}

.img__btn:after {
  content: "";
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 30px;
}

.img__btn span {
  position: absolute;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

h2 {
  color: black;
  width: 100%;
  font-size: 26px;
  text-align: center;
}

label {
  display: block;
  width: 260px;
  margin: 40px auto 0;
  text-align: center;
}

label span {
  font-size: 16px;
  color: black;
  text-transform: uppercase;
}

input {
  display: block;
  width: 100%;
  margin-top: 15px;
  /* padding-bottom: 5px; */
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  text-align: center;
  line-height: 26px;
  height: 26px;
}

.forgot-pass {
  margin-top: 15px;
  text-align: center;
  font-size: 12px;
  color: #cfcfcf;
}

.submit {
  margin-top: 40px;
  margin-bottom: 20px;
  background: rgb(61, 129, 255) !important;
  text-transform: uppercase;
}

.fb-btn {
  border: 2px solid #d3dae9;
  color: #8fa1c7;
}

.fb-btn span {
  font-weight: bold;
  color: #455a81;
}

.sign-in {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.cont.s--signup .sign-in {
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 1.2s;
  transition-duration: 1.2s;
  -webkit-transform: translate3d(640px, 0, 0);
  transform: translate3d(640px, 0, 0);
}

.sign-up {
  -webkit-transform: translate3d(-900px, 0, 0);
  transform: translate3d(-900px, 0, 0);
}

.cont.s--signup .sign-up {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.icon-link {
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 32px;
}

.icon-link img {
  width: 100%;
  vertical-align: top;
}

.icon-link--twitter {
  left: auto;
  right: 5px;
}

/* topbanner */
.topBanner {
  background: white;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.logo {
  width: 300px;
  padding: 28px;
}

.welcome {
  font-size: 16px;
  color: white;
  height: 99px;
  line-height: 99px;
  /* margin-right: 18px; */
  display: flex;
}

.userName {
  margin-right: 10px;
}

.el-button--text {
  color: rgb(61, 129, 255);
  margin: 10px;
}

.notice {
  text-align: left;
  font-size: 17px;
}
</style>