let upperLetter = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
let lowLetter = "abcdefghijklmnopqrstuvwxyz";
let number = "0123456789"
let sum = upperLetter + lowLetter + number;
let length = sum.length
function creatRandom() {
    let random = []
    for (let i = 0; i < 5; i++) {
        random[i] = sum[Math.ceil(62 * Math.random())]

    }
    return random
}

// function dramCanvas(){
//     for(let i;i<5;i++)
// }

export default {
    phoneNumber(phone) {
        if (!(/^1(3|4|5|7|8|9)\d{9}$/.test(phone))) {
            return false;
        } else {
            return true
        }
    },
    cardId(cardid) {
        if (!(/^[1-9]{1}[0-9]{14}$|^[1-9]{1}[0-9]{16}([0-9]|[xX])$/.test(cardid))) {
            return false;
        } else {
            return true
        }
    },
    passWord(password) {
        if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(password))) {
            return false;
        } else {
            return true
        }
    },
    veryLengthMin(value, min) {
        return value.length < min ? false : true
    },
    veryLengthMax(value, max) {
        return value.length > max ? false : true
    } ,
    // veryLengthMinMax(value,max) {
    //     return value.length > max ? false : true
    // }
}

